<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g">
        <div class="card card-body">
            <p>الفنادق</p>
            <div class="col-12 text-center">
            <img
                :src="image ? image : require('@/assets/images/avatar.png')"
                style="
                width: 100px;
                height: 100px;
                cursor: pointer;
                object-fit: cover;
                border-radius: 50%;
                "
            />
            <div id="progress-wrp">
                <div class="progress-bar"></div>
                <div class="status">0%</div>
            </div>
            <br />
            <input type="file" title="اضغط لتغيير الصورة" id="ingredient_file" />
            </div>
            <div class="form-group">
            <label for="">اسم الفندق</label>
            <input type="text"
                class="form-control" v-model="title">
            </div>
            <div class="form-group">
            <label for="">رقم الهاتف</label>
            <input type="text"
                class="form-control" v-model="phone">
            </div>
            <div class="form-group">
                <label for=""> الشركة</label>
                <select class="form-control" v-model="company_id" name="" id="">
                    <option :value="null">-- اختر --</option>
                    <option v-for="city in companies" :key="city._id" :value="city._id">{{ city.title }}</option>
                </select>
            </div>
            <div class="form-group">
                <label for=""> المدينة</label>
                <select class="form-control" v-model="city_id" name="" id="">
                    <option :value="null">-- اختر --</option>
                    <option v-for="city in settings.cities" :key="city.id" :value="city.id">{{ city.title }}</option>
                </select>
            </div>
            <div class="form-group">
            <label for="">اللوكيشن</label>
            <input type="text"
                class="form-control" v-model="location">
            </div>
            <div class="form-group">
            <label for="">عمولة المحصل</label>
            <input type="number"
                class="form-control" v-model="cash_tips">
            </div>
            <div class="col-12 table-responsive">
                <table class="table table-bordered table-hover">
                    <thead>
                        <th>
                            اليوم
                        </th>
                        <th>
                            السعر
                        </th>
                        <th>
                            السعر للوكيل
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="(price) in prices" :key="price">
                            <td>
                                {{ day[price.i] }}
                            </td>
                            <td>
                                <input type="number" v-model="price.price" style="width: 100%; text-align: center; border: none;">
                            </td>
                            <td>
                                <input type="number" v-model="price.reseller" style="width: 100%; text-align: center; border: none;">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12 text-center g" @click="addItem()">
                <button class="btn btn-primary">
                    <i class="fa fa-save"></i>
                    حفظ
                </button>
            </div>
            <div class="col-12" v-if="error">
                <div class="alert alert-danger text-center g">
                    <h4>
                        {{ error }}
                    </h4>
                </div>
            </div><br><br>
            <button class="btn btn-sm text-danger" @click="deleteItem(id)">
                <i class="fa fa-trash"></i>
                حذف نهائياً
            </button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            jwt: JSON.parse(localStorage.getItem("user")).jwt,
            arr: [],
            error: "",
            settings: {},
            title: "",
            cash_tips: 0,
            location: "",
            company_id: null,
            id: window.location.href.split("hotels/")[1],
            prices: {
                Saturday: {
                    i: 0,
                    price: 0,
                    reseller: 0
                },
                Sunday: {
                    i: 1,
                    price: 0,
                    reseller: 0
                },
                Monday: {
                    i: 2,
                    price: 0,
                    reseller: 0
                },
                Tuesday: {
                    i: 3,
                    price: 0,
                    reseller: 0
                },
                Wednesday: {
                    i: 4,
                    price: 0,
                    reseller: 0
                },
                Thursday: {
                    i: 5,
                    price: 0,
                    reseller: 0
                },
                Friday: {
                    i: 6,
                    price: 0,
                    reseller: 0
                },
            },
            day: [
                "السبت",
                "الاحد",
                "الاثنين",
                "الثلاثاء",
                "الاربعاء",
                "الخميس",
                "الجمعة"
            ],
            phone: "",
            image: "",
            companies: [],
            city_id: null
        }
    },
    created(){
        var g = this;
         $.post(api + '/admin/companies/all', {
            jwt: this.jwt
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                if(r.response){
                    g.companies = r.response
                }
            }
        }).fail(function(){
            alert("حدث مشكلة في الاتصال")
        })
        $.post(api + '/admin/hotels/all', {
            jwt: this.jwt
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                if(r.response){
                    g.arr = r.response
                    g.arr.forEach(element => {
                        if(element._id == window.location.href.split("hotels/")[1]){
                            g.company_id = element.company_id;
                            g.title = element.title;
                            g.city_id = element.city_id;
                            g.location = element.location;
                            g.image = element.image;
                            g.phone = element.phone;
                            g.cash_tips = element.cash_tips;
                            g.prices = element.prices;
                        }
                    });
                }
            }
        }).fail(function(){
            alert("حدث مشكلة في الاتصال")
        })
        $.post(api + '/admin/general/settings', {
            jwt: this.jwt
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                if(r.response){
                    if(r.response.send_withdraw != 'yes' && r.response.send_withdraw != 'true'){
                        r.response.send_withdraw = false;
                    }
                    g.settings = r.response
                }
            }
        }).fail(function(){
            alert("حدث مشكلة في الاتصال")
        })
        var Upload = function (file) {
      this.file = file;
    };

    Upload.prototype.getType = function () {
      return this.file.type;
    };
    Upload.prototype.getSize = function () {
      return this.file.size;
    };
    Upload.prototype.getName = function () {
      return this.file.name;
    };
    Upload.prototype.doUpload = function () {
      var that = this;
      var formData = new FormData();

      // add assoc key values, this will be posts values
      formData.append("file", this.file, this.getName());
      formData.append("jwt", g.jwt);
      $.ajax({
        type: "POST",
        url: api + "/admin/general/upload",
        xhr: function () {
          var myXhr = $.ajaxSettings.xhr();
          if (myXhr.upload) {
            myXhr.upload.addEventListener(
              "progress",
              that.progressHandling,
              false
            );
          }
          return myXhr;
        },
        success: function (data) {
          $("#progress-wrp").fadeOut("slow");
          g.image = data.response;
        },
        error: function (error) {
          // handle error
          alert("حدث خطأ");
        },
        async: true,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        timeout: 60000,
      });
    };

    Upload.prototype.progressHandling = function (event) {
      var percent = 0;
      var position = event.loaded || event.position;
      var total = event.total;
      var progress_bar_id = "#progress-wrp";
      if (event.lengthComputable) {
        percent = Math.ceil((position / total) * 100);
      }
      // update progressbars classes so it fits your code
      $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
      $(progress_bar_id + " .status").text(percent + "%");
    };
    setTimeout(() => {
      $("#ingredient_file").on("change", function (e) {
        $("#progress-wrp").fadeIn("slow");
        var file = $(this)[0].files[0];
        var upload = new Upload(file);

        // maby check size or type here with upload.getSize() and upload.getType()

        // execute upload
        upload.doUpload();
      });
    }, 1000);
    },
    methods: {
        addItem(){
            var g =this;
            if(g.title && g.title != "" && g.city_id && g.company_id){
                $.post(api + '/admin/hotels/edit', {
                    id: window.location.href.split("hotels/")[1],
                    jwt: this.jwt,
                    title: g.title,
                    city_id: g.city_id,
                    company_id: g.company_id,
                    location: g.location,
                    image: g.image,
                    phone: g.phone,
                    prices: g.prices,
                    cash_tips: g.cash_tips
                }).then(function(r){
                    if(r.status != 100){
                        g.error = r.response
                    }else{
                        location.reload()
                    }
                }).fail(function(){
                    g.error = "حدث مشكلة في الاتصال"
                })
            }
        },
        deleteItem(id){
            var g = this;
            if(confirm("متأكد من حذف الفندق نهائياً؟")){
                $.post(api + '/admin/hotels/delete', {
                    jwt: this.jwt,
                    id: id
                }).then(function(r){
                    if(r.status != 100){
                        g.error = r.response
                    }else{
                        window.location = '/hotels'
                    }
                }).fail(function(){
                    g.error = "حدث مشكلة في الاتصال"
                })
            }
        }
    },
}
</script>

<style>

</style>
<style scoped>
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style>